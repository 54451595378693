import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import swal from 'sweetalert2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { UsedTaxisComponent } from './components/used-taxis/used-taxis.component';
import { ShowroomComponent } from './components/showroom/showroom.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SiteDirectoryComponent } from './components/site-directory/site-directory.component';
import { SiteTipsComponent } from './components/site-tips/site-tips.component';
import { FormsModule } from '@angular/forms';
import { ViewTaxiComponent } from './components/view-taxi/view-taxi.component';
import { NewTaxisComponent } from './components/new-taxis/new-taxis.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';

import {DealerContact} from './components/view-taxi/view-taxi.component';

import { LoginComponent } from './dealer-admin/login/login.component';
import { TaxiNewsComponent } from './components/taxi-news/taxi-news.component';
import { DashboardComponent } from './dealer-admin/dashboard/dashboard.component';
import { CustomerContactComponent } from './dialogs/customer-contact/customer-contact.component';
import { VehicleEditorComponent } from './dealer-admin/vehicle-editor/vehicle-editor.component';
import { ListVehiclesComponent } from './dealer-admin/list-vehicles/list-vehicles.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    FooterComponent,
    HomePageComponent,
    UsedTaxisComponent,
    ShowroomComponent,
    SearchBoxComponent,
    SiteDirectoryComponent,
    SiteTipsComponent,
    ViewTaxiComponent,
    NewTaxisComponent,
    ContactUsComponent,
    AboutUsComponent,
    LoginComponent,
    DealerContact,
    TaxiNewsComponent,
    DashboardComponent,
    CustomerContactComponent,
    VehicleEditorComponent,
    ListVehiclesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
   BrowserAnimationsModule,
   MatToolbarModule,
   MatSelectModule,
   MatButtonModule,
   MatCardModule,
   MatTabsModule,
   MatListModule,
   MatGridListModule,
   MatIconModule,
   MatTableModule,
   MatBottomSheetModule,
   MatInputModule,
   MatSnackBarModule,
   MatListModule,
   MatDialogModule
   
   
    
  ],
  providers: [],
  entryComponents: [
    DealerContact,
    CustomerContactComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
