import { Component, OnInit , Input } from '@angular/core';
import { UiStateService } from '../../services/ui-state.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

 @Input() _isLoggedIn : Boolean;

  constructor(private uiState : UiStateService) { }

  ngOnInit() {
  }

  logout(){
    this.uiState.logout();
  }

}
