export class  ContactDealer{

constructor(
    public taxiId : number,
    public taxiType : string,
    public email : string,
    public name : string,
    public mobile : string,
    public work : string,
    public area : string,
    public message : string,
    public dealerId?: number,
    public dealerEmail? : string

){}

}