import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../helper-classes/User';
import { DealerContact } from '../models/dealerContact';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = 'http://www.taxis4sa.co.za/taxis4sabeta/api.php/user';

  constructor(private http : HttpClient) { }

  async getLogin(email, password ){
    
    let response = await this.http.get<User>(this.url + '?transform=1&colums=id,dealer_id&filter[]=email,eq,'+email+'&filter[]=password,eq,'+ password )
    .toPromise();
    //console.log(response);
   
    return response;
}

async getUserDetails(id){
  let response = await this.http.get<User>(this.url + '?transform=1&colums=id,dealer_id,email,name,surname,phone&filter=id,eq,'+id)
  .toPromise();
  return response;
}

async getDealerMessages(id){
  const url = 'http://www.taxis4sa.co.za/taxis4sabeta/api.php/dealerContact';
  let response = await this.http.get<DealerContact>(url + '?transform=1&filter=dealerId,eq,'+id)
  .toPromise();
  return response;
}

}
