export class SearchFilter {

    constructor() {

    }

    filterMakes(year, vehicleArray) {
        let makes = [];

        if (year == '') {
            for (var i = 0; i < vehicleArray.length; i++) {

                if (i == 0) {
                    makes.push(vehicleArray[i]['make']);
                } else {
                    if (makes.includes(vehicleArray[i]['make']) == false) {
                        makes.push(vehicleArray[i]['make']);
                    }
                }

            }
        } else {
            for (var i = 0; i < vehicleArray.length; i++) {
                if (vehicleArray[i]['year'] == year) {
                    if (i == 0) {
                        makes.push(vehicleArray[i]['make']);
                    } else {
                        if (makes.includes(vehicleArray[i]['make']) == false) {
                            makes.push(vehicleArray[i]['make']);
                        }
                    }
                }
            }
        }
        return makes;


    }


    filterModels(make, vehicleArray) {
        let models = [];
        for (var i = 0; i < vehicleArray.length; i++) {
            if (vehicleArray[i]['make'] == make) {
                if (i == 0) {
                    models.push(vehicleArray[i]['model']);
                } else {
                    if (models.includes(vehicleArray[i]['model']) == false) {
                        models.push(vehicleArray[i]['model']);
                    }
                }
            }
        }

        return models;


    }

    filterVariants(model, vehicleArray) {
        let variants = [];
        for (var i = 0; i < vehicleArray.length; i++) {
            if (vehicleArray[i]['model'] == model) {
                if (i == 0) {
                    variants.push(vehicleArray[i]['variant']);
                } else {
                    if (variants.includes(vehicleArray[i]['variant']) == false) {
                        variants.push(vehicleArray[i]['variant']);
                    }
                }
            }
        }

        return variants;


    }

    

    searchMileage(mileage,vehiclesArray){
        let vehicles = [];
        for(let i = 0; i < vehiclesArray.length; i++){
            if(vehiclesArray[i]['mileage'] == mileage){
                vehicles.push(vehiclesArray);
            }
        }
        return vehicles;
    } 


    searchArea(area, vehiclesArray){
        let vehicles = [];
        for(let i = 0; i < vehiclesArray.length; i++){
            if(vehiclesArray[i]['province'] == area){
                vehicles.push(vehiclesArray);
            }
        }
        return vehicles;
    }

    adjustContent(criteria, vehicles){
        
        let display = [];

        let year = criteria[0];
        let make = criteria[1];
        let model = criteria[2];
        let variant = criteria[3];

        if(year === false && make === false){
            display = vehicles;
        }
        else if (year === true && make === false){
            for(let i = 0 ; i <  vehicles.length; i++){
                if( vehicles[i]['year'] === sessionStorage.getItem('year')){
                    display.push(vehicles[i]);
                }
            }
            //console.log(display);
        }
        else if(year === false && make === true){
            for(let i = 0 ; i < vehicles.length ; i++){
                if( vehicles[i]['make'] === sessionStorage.getItem('make')){
                    display.push(vehicles[i]);
                }
            }
            //console.log(display);
        }

        
        return display;

    }

}