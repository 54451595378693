import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeSearchService } from '../../home-search.service';

@Component({
  selector: 'app-site-directory',
  templateUrl: './site-directory.component.html',
  styleUrls: ['./site-directory.component.css']
})
export class SiteDirectoryComponent implements OnInit {

  makes : any;
  regions =[];

  constructor(private http : HttpClient , private homeSearch : HomeSearchService) { }

  ngOnInit() {
   this.getMakes();
   console.log(this.makes);
  }

  getMakes() : any{
    
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/minibus?transform=1&columns=MAKE')
    .subscribe((res : JSON)=>{
      let m = res['minibus'];
      this.makes = [];
      for(let i = 0;i < m.length ; i++){
        if(i === 0){
          this.makes.push(m[i]['MAKE']);
        }
        else if(i > 0){
          if(this.makes.includes(m[i]['MAKE']) === false){
              this.makes.push(m[i]['MAKE']);
          }
        }
      }
     console.log(this.makes);
    });
  
    
  }

  searchMake(make){
    let data = [make , undefined , undefined , undefined , undefined , "Used"];
    this.homeSearch.setSearch(data);
  }
  searchArea(area){
    let data = [undefined , undefined , area , undefined , undefined , "Used"];
    this.homeSearch.setSearch(data);
  }
}
