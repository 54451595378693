import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from '../app/components/home-page/home-page.component';
import { ShowroomComponent } from '../app/components/showroom/showroom.component'
import { UsedTaxisComponent } from '../app/components/used-taxis/used-taxis.component';
import { NewTaxisComponent } from '../app/components/new-taxis/new-taxis.component';
import { ViewTaxiComponent } from '../app/components/view-taxi/view-taxi.component';
import { ContactUsComponent } from '../app/components/contact-us/contact-us.component';
import { AboutUsComponent } from '../app/components/about-us/about-us.component';
import { TaxiNewsComponent } from '../app/components/taxi-news/taxi-news.component';
import { LoginComponent } from '../app/dealer-admin/login/login.component';
import { DashboardComponent } from '../app/dealer-admin/dashboard/dashboard.component';
import { VehicleEditorComponent } from '../app/dealer-admin/vehicle-editor/vehicle-editor.component'
import { ListVehiclesComponent } from '../app/dealer-admin/list-vehicles/list-vehicles.component';

const routes: Routes = [

  {
    path: '',
    component: HomePageComponent
  },
  {
    path : 'showroom',
    component : ShowroomComponent
  },
  {
    path : 'used-taxis',
    component : UsedTaxisComponent
  },
  {
    path : 'new-taxis',
    component : NewTaxisComponent
  },
  {
    path : 'view-taxi/:type/:id',
    component : ViewTaxiComponent
  },
  {
    path : 'contact-us',
    component : ContactUsComponent
  },
  {
    path : 'about-us',
    component : AboutUsComponent
  },
  {
    path : 'taxi-news/:id',
    component : TaxiNewsComponent
  },
  {
    path : 'dealer-login',
    component : LoginComponent 
  },
  {
    path : 'dealer-dashboard',
    component : DashboardComponent
  },
  {
    path : 'manage-taxi/:id',
    component : VehicleEditorComponent
  },
  {
    path : 'listed-vehicles',
    component : ListVehiclesComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
