import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-taxi-news',
  templateUrl: './taxi-news.component.html',
  styleUrls: ['./taxi-news.component.css']
})
export class TaxiNewsComponent implements OnInit {

  id : any ;
  article  = [];

  constructor(private http : HttpClient , private route: ActivatedRoute) { 

  }

  ngOnInit() {

    this.route.params.subscribe( params => this.id = params.id);
    console.log(this.id);

    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/Taxis4saNews?transform=1&filter=id,eq,' + this.id)
    .subscribe((res : JSON) =>{
        let a = res['Taxis4saNews'][0];
        this.article = a;
        console.log(this.article);
    
    });

  }


}
