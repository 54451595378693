import { Component, OnInit , Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router , ActivatedRoute} from "@angular/router";
import { ContactDealer } from '../../contact-dealer';

import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
 
@Component({
  selector: 'app-view-taxi',
  templateUrl: './view-taxi.component.html',
  styleUrls: ['./view-taxi.component.css']
})


export class ViewTaxiComponent implements OnInit {

vehicle_info  : any;
vehicle : any;
previousPage : string;
make : any;
model : any;
variant : any;
stock_number : any;
images : Array<string>;
// contactModel : ContactDealer = new ContactDealer(0,'','','','','','','');
dealerId : number;
dealer_email : string;
  constructor(private route: ActivatedRoute, private http : HttpClient, private bottomSheet: MatBottomSheet) {

    this.route.params.subscribe( params => this.vehicle_info = params);
    
   }

  ngOnInit() {
    this.getVheicleInfo();
    
  }

  openBottomSheet(): void {
    this.bottomSheet.open(DealerContact, {
      data: { vehicle_info : this.vehicle_info , dealerInfo :  this.dealerId , addOns : { make : this.make , model : this.model , variant : this.variant , stock_number : this.stock_number}},
    });
  }

  getVheicleInfo(){
    let table = '';
    if(this.vehicle_info['type'] === 'new'){
      table  = 'newTaxis';
      this.previousPage = 'New Taxis';

    }else{
      table = 'used';
      this.previousPage = 'Used Taxis';
    }

    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/' + table + '?transform=1&filter=id,eq,' + this.vehicle_info['id'])
    .subscribe((res : JSON)=>{
      let v = res[table][0];
      this.vehicle = v;
      this.make = this.vehicle['make'];
      this.model = this.vehicle['model'];
      this.variant = this.vehicle['variant'];
      this.dealerId = this.vehicle['dealer_code'];
      this.stock_number = this.vehicle['stock_number'];
      this.images = [v['img_1'],v['img_2'],v['img_3'],v['img_4']]
    });

    
  }





}

@Component({
  selector: 'contact-dealer-view',
  templateUrl: 'dealer-contact-bottom.html',
  styleUrls: ['dealer-contact-bottom.css'],
})

export class DealerContact {

  vehicle_info  = [];
  addOns = [];
  dealerId  : any;
  dealer_email : string;
  contactModel : ContactDealer = new ContactDealer(0,'','','','','','','');
  
  constructor( private bottomSheetRef: MatBottomSheetRef<DealerContact> , private http : HttpClient , @Inject(MAT_BOTTOM_SHEET_DATA) public data: any , public snackBar: MatSnackBar) {
    console.log(data);
    this.dealerId = data['dealerInfo'];
    this.vehicle_info = data['vehicle_info'];
    this.addOns = data['addOns'];
    //this.getDealerInfo();
  }

  getDealerInfo(){
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/dealerdirectory?transform=1&columns=email&filter=dealer_id,eq,' + this.dealerId)
    .subscribe((res : JSON)=>{
        this.dealer_email = res['dealerdirectory'][0]['email'];
        
    });
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  sendContactMessage(data){
    console.log(data);
    let contact = new ContactDealer(this.vehicle_info['id'], this.vehicle_info['type'] , data['email'], data['name'], data['mobile'] , data['work'], data['area'], data['message'], this.dealerId, this.dealer_email);
    this.http.post('http://www.taxis4sa.co.za/taxis4sabeta/api.php/dealerContact', 
    contact)
    .subscribe((res : JSON)=>{
      if(res != null){
      //Send request to email API
      let fullMsg = data['message'] + ' \n' + this.addOns['make'] + ' \n' + this.addOns['model'] + ' \n' + this.addOns['variant'] + ' \n' + this.vehicle_info['id'] + "\n " + "vehicle type : " +  this.vehicle_info['type'];
        this.http.post('http://www.taxis4sa.co.za/taxis4sabeta/dealerContact.php', 
        {dealerEmail : "delroy.granger1@gmail.com", email : data['email'] ,name :  data['name'] ,mobile : data['mobile'], work : data['work'], area : data['area'], message : fullMsg })
        .subscribe((r : JSON)=>{
          console.log(this.dealer_email);
                  

            this.snackBar.open("The dealer has been notiied!", "Ok", {
              duration: 2000,
            });
          
          
        });


        
      }
    });

    
  }

}
