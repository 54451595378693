import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HomeSearchService } from '../../home-search.service';
import swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})


export class SearchBoxComponent implements OnInit {

make : string;
model : string;
province : string;
city : string;
price : string;
type : string;
types = ['used', 'new'];


  makes = []
  models = []
  provinces = [];
  cities = [];
  prices  =  [ 
    { 'value' : "0,24999", 'display' : "R 0 - R 24,999"},
    { 'value' : "24999,49999" , 'display' : "R 25,000 - R 49,999"},
    { 'value' : "49999,74999" , 'display' : "R 50,000 - R 74,999"},
    { 'value' : "74999,99999" , 'display' : "R 75,000 - R 99,999"},
    { 'value' : "99999,124999", 'display' : "R 100,000 - R 124,999"},
    { 'value' : "124999,149999" , 'display' : "R 125,000 - R 149,999"},
    { 'value' : "149999,199999" , 'display' : "R 150,000 - R 199,999"},
    { 'value' : "199999,299999", 'display' : "R 200,000 - R 299,999"},
    { 'value' : "299999,399999" , 'display' : "R 300,000 - R 399,999"},
    { 'value' : "399999,499999" , 'display' : "R 400,00 - R 499,999"},
    { 'value' : "499999,599999" , 'display' : "R 500,000 - R 599,999"}
     
  ];

  
  constructor(private http : HttpClient, private homeSearch : HomeSearchService) { }

  ngOnInit() {
   
    //GET MEAD
    let filter = '';
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/minibus?transform=1&columns=MAKE,MODEL')
    .subscribe((response : JSON) =>{
      let details = response['minibus'];

      for(let i = 0; i < details.length;i++){
        if(i == 0){
            this.makes.push(details[i]['MAKE']);
        }
        if(i != 0){
          if(this.makes.includes(details[i]['MAKE']) === false){
            this.makes.push(details[i]['MAKE']);
          }
        }

      }

    });
    this.getProvince();
  }



  getModels(e){
    this.models= [];
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/minibus?transform=1&columns=MODEL&filter=MAKE,eq,'+e)
    .subscribe((response : JSON) =>{
      let details = response['minibus'];
      for(let i = 0; i < details.length;i++){
        if(i == 0){
            this.models.push(details[i]['MODEL']);
        }
        if(i != 0){
          if(this.models.includes(details[i]['MODEL']) === false){
            this.models.push(details[i]['MODEL']);
          }
        }

      }
    });
  }
  
  getProvince(){
    this.provinces= [];
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/location?transform=1&columns=province')
    .subscribe((response : JSON) =>{
      let details = response['location'];
      for(let i = 0; i < details.length;i++){
        if(i == 0){
            this.provinces.push(details[i]['province']);
        }
        if(i != 0){
          if(this.provinces.includes(details[i]['province']) === false){
            this.provinces.push(details[i]['province']);
          }
        }

      }
    });
  }

  getCity(e){
    this.cities= [];
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/location?transform=1&columns=accent_city&filter=province,eq,'+ e)
    .subscribe((response : JSON) =>{
      let details = response['location'];
      for(let i = 0; i < details.length;i++){
        if(i == 0){
            this.cities.push(details[i]['accent_city']);
        }
        if(i != 0){
          if(this.cities.includes(details[i]['accent_city']) === false){
            this.cities.push(details[i]['accent_city']);
          }
        }

      }
    });
  }

  priceRange(e){
    this.price = e; 
  }

  search(){
    //let data = [this.make , this.model , this.provice, this.city , this.price , this.type];
    let data : any = { type : this.type ,  make : this.make, model : this.model, province : this.province , price: this.price }
    this.homeSearch.search(<JSON>data);
    //this.homeSearch.setSearch(data);
  }
}
