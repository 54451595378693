import { Component, OnInit } from '@angular/core';
import { VehiclesServiceService } from '../../services/vehicles-service.service';
import {ActivatedRoute} from "@angular/router";
import { Vehicle } from '../../models/vehicles';

@Component({
  selector: 'app-vehicle-editor',
  templateUrl: './vehicle-editor.component.html',
  styleUrls: ['./vehicle-editor.component.css']
})
export class VehicleEditorComponent implements OnInit {

  vehicle_id : number;
  vehicle : Vehicle;
  images = [];
  editMode : Boolean = false;
  btn_edit : string = "Edit";
  
  constructor(private vehicleService : VehiclesServiceService, private route: ActivatedRoute) {
    this.route.params.subscribe( params => this.vehicle_id = params['id'] );
    
   }

  ngOnInit() {
    this.getInformation();
  }

  editVehicle(){
      this.editMode = true;
      this.btn_edit = "Save";
  }

  async saveChanges(){
    this.editMode = false;
    this.btn_edit = "Edit";
    const response  = await this.vehicleService.editVehicle(this.vehicle);
    console.log(response);
  }

  async getInformation(){

    const vehicleInfo = await this.vehicleService.getVehicle(this.vehicle_id);
    
    this.vehicle = vehicleInfo['used'][0];
    this.images = [this.vehicle['img_1'] , this.vehicle['img_2'], this.vehicle['img_3'], this.vehicle['img_4']];
    //this.vehicle = vehicleInfo;
    // console.log(this.vehicle);
  }

}
