import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HomeSearchService {

  constructor(private http: HttpClient, private router: Router) { }
  private searchData: Array<any>;
  private criteria : JSON;

  search(data: JSON) {
    this.criteria = data;

    let type : string;

    if(data['type'] === undefined){
      type = 'new';
      this.criteria['type'] = 'new';
    }else{
      type = data['type'];
     
    }
    console.log(this.criteria);
    const filter = "&filter[]=make,eq," + data['make'] +
      "&fiter[]=model,eq," + data['model'] +
      "&filter[]=price,bt," + data['price'] +
      "&filter[]=province,eq," + data['province'] +
      "&satisfy=any";

    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/' + type + '?transform=1' + filter)
      .subscribe((res: JSON) => {
        try {
          if (res[type].length === 0) {
            this.router.navigate(['/new-taxis']);
          } else {
            console.log(res[type]);
            this.searchData = res[type];
            this.router.navigate(['/showroom']);
          }
        }
        catch (e) {
          console.log(e);
        }

      });

  }

  setSearch(data: Array<any>) {

    let table = '';
    let filter = '';
    let result = false;
    //Filters
    if (data[0] != undefined) {
      filter = filter + "&filter[]=make,eq," + data[0];
      this.criteria[0] = data[0];
    }

    if (data[1] != undefined) {
      filter = filter + "&filter[]=model,eq," + data[1];
      this.criteria[1] = data[1];
    }

    if (data[2] != undefined) {
      filter = filter + "&filter[]=provice,eq," + data[2];
      this.criteria[2] = data[2];
    }

    if (data[4] != undefined) {
      filter = filter + "&filter[]=price,eq" + data[4];
      this.criteria[4] = data[4];
    }

    if (data[5] != undefined) {
      if (data[5] === 'New') {
        table = 'newTaxis';

      } else {
        table = 'usedTaxis';
      }
      this.criteria[5] = data[5];
    }

    // no table specified
    if (data[5] === '' || data[5] === undefined || data[5] === null) {
      table = 'newTaxis';
      this.criteria[5] = 'New';
    }


    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/' + table + '?transform=1' + filter)
      .subscribe((res: JSON) => {
        try {
          if (res[table].length === 0) {
            this.router.navigate(['/new-taxis']);
          } else {
            this.searchData = res[table];
            this.router.navigate(['/showroom']);
          }
        }
        catch (e) {
          console.log(e);
        }

      });



  }

  getSearch() {
    return this.searchData;
  }
  getCriteria() {
    return this.criteria;
  }

}
