import { Component, OnInit } from '@angular/core';
import {Router , ActivatedRoute} from "@angular/router";
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material';
import { UiStateService } from '../../services/ui-state.service';


import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  _isLoggedin : Boolean;
  _formComplete :Boolean;
  _email : string = '';
  _password : string = '';

  
  
  constructor(private router : Router, private http : HttpClient, private user_service : UserService, public snackBar: MatSnackBar, private uiState : UiStateService) {
    

    
   }

  ngOnInit() {


  }



  async login(){

    if(this._email === undefined || this._password === undefined){
      console.log(this._password);
    }
    else{
      let login : any  = await this.user_service.getLogin(this._email,this._password);
      if(login['user'].length !== 0){
        //Login Accepted
        sessionStorage.setItem('uid', login['user'][0]['id']);
        sessionStorage.setItem('did', login['user'][0]['dealer_id']);
        this.uiState.setLoggedIn();
        this.router.navigate(['/dealer-dashboard']);
      }else{
        //Could not find user
       
        this.snackBar.open("Email or Password are incorrect!", "Ok", {
          duration: 2000,
        });
      }
     
    }

  }

}

