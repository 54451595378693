import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchFilter } from '../../search-filter';
import swal from 'sweetalert2';

import { Sorter } from '../../helper-classes/sorter';

@Component({
  selector: 'app-used-taxis',
  templateUrl: './used-taxis.component.html',
  styleUrls: ['./used-taxis.component.css']
})
export class UsedTaxisComponent implements OnInit {

  vehicles = [];
  displayArray = [];
  displayLength : number;
  total : number;
  currentPage = 1;
  smallnumPages = 0;
  page : number;

  years = [];
  makes = [];
  models = [];
  variants = [];
  mileages = [];
  areas = [];

  filter : boolean = false;
  yearSet : boolean  = false;
  makeSet : boolean  = false;
  modelSet : boolean  = false;
  variantSet : boolean  = false;
  mileageSet : boolean  = false;
  areaSet : boolean  = false;
  temp  = [];
  criterias = [];
  
  selectedPriceFilter = 'low_price';
  sorter : Sorter;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    
    this.getUsedVehicles();
    
    
  }

  getUsedVehicles(){
    this.vehicles = [];
    let columns = '&columns=id,price,year,make,model,variant,mileage,province,drivetrain,img_1,colour,type';
    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/used?transform=1&order=id,desc' + columns)
    .subscribe((data : JSON)=>{
      //console.log(data);
      this.vehicles = data['used'];
      this.total = this.vehicles.length;
      this.displayArray = this.vehicles.slice(0, 10);

      this.years = [];
      //Populate side-search bar
        for(let i = 0 ; i < data['used'].length; i++){
          if(i == 0){
            this.years.push(data['used'][i]['year']);
            this.makes.push(data['used'][i]['make']);
            this.models.push(data['used'][i]['model']);
            this.variants.push(data['used'][i]['variant']);
            this.mileages.push(data['used'][i]['mileage']);
            this.areas.push(data['used'][i]['province']);
          }
          if(i != 0){
            if(this.years.includes(data['used'][i]['year']) === false){
              this.years.push(data['used'][i]['year']);
            }
            else if(this.makes.includes(data['used'][i]['make']) === false){
              this.makes.push(data['used'][i]['make']);
            }
            else if(this.models.includes(data['used'][i]['model']) === false){
              this.models.push(data['used'][i]['model']);
            }
            else if(this.variants.includes(data['used'][i]['variant']) === false){
              this.variants.push(data['used'][i]['variant']);
            }
            else if(this.mileages.includes(data['used'][i]['mileage']) === false){
              this.mileages.push(data['used'][i]['mileage']);
            }
            else if(this.areas.includes(data['used'][i]['province']) === false){
              this.areas.push(data['used'][i]['province']);
            }
        }
      }//End For


      this.displayLength = this.displayArray.length;

      this.years = this.years.sort(function(a, b){return b-a});
      this.makes = this.makes.sort();
      this.models = this.models.sort();
      this.variants = this.variants.sort();
      this.mileages = this.mileages.sort();
      this.areas = this.areas.sort();
     
      this.filter = false;
      this.yearSet = false;
      this.makeSet = false;
      this.modelSet = false;
      this.variantSet = false;
      this.mileageSet = false;
      this.areaSet = false;
      
      this.sortPrice(this.selectedPriceFilter);
    });

    

  }
  pageChanged(event: any): void {
    this.page = event.page;
    
    if(this.filter === false){
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.displayArray = this.vehicles.slice(startItem, endItem);
      this.displayLength = this.displayArray.length;
    }
    else if(this.filter === true){
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.displayArray = this.temp.slice(startItem, endItem);
      this.displayLength = this.displayArray.length;
    }

    
  }

  sortPrice(criteria){
    console.log(criteria);
    
    if(this.filter){
      this.sorter = new Sorter(this.temp);
    }else{
      this.sorter = new Sorter(this.vehicles);
    }

    switch(criteria){
      case "low_price" : 
        if(this.filter === false){
          this.vehicles =  this.sorter.priceLowToHigh();
          this.displayArray = [];
          this.displayArray = this.vehicles.slice(0, 10);
          
          this.displayLength = this.displayArray.length;
          this.selectedPriceFilter = criteria;
        }else{
          this.temp =  this.sorter.priceLowToHigh();
          this.displayArray = [];
          this.displayArray = this.temp.slice(0, 10);
          this.displayLength = this.displayArray.length;
          this.selectedPriceFilter = criteria;
        }

         break;
      case "high_price" :
        if(this.filter === false){
          this.selectedPriceFilter = criteria
          this.vehicles = this.sorter.priceHighToLow();
          this.displayArray = [];
          this.displayArray = this.vehicles.slice(0, 10);
          this.displayLength = this.displayArray.length;
        }else{
          this.selectedPriceFilter = criteria
          this.temp = this.sorter.priceHighToLow();
          this.displayArray = [];
          this.displayArray = this.temp.slice(0, 10);
          this.displayLength = this.displayArray.length;
        }

        break;
    }
  }

  removeItem(e){
    
    switch(e){
      case 1:
        this.yearSet = false;
        this.criterias = [this.yearSet, this.makeSet, this.modelSet , this.variantSet];
        this.displayArray = new SearchFilter().adjustContent(this.criterias, this.vehicles);
        this.total = this.displayArray.length;
        this.makes = [];
        this.makes = new SearchFilter().filterMakes('',this.vehicles);
        break;
      case 2 : 
        this.makeSet = false;
        this.criterias = [this.yearSet, this.makeSet, this.modelSet , this.variantSet];
        this.displayArray = new SearchFilter().adjustContent(this.criterias, this.vehicles);
        this.total = this.displayArray.length;
        this.models = [];
        this.models = new SearchFilter().filterModels('',this.vehicles);
        break;
      case 3 : 
        this.modelSet = false;
        this.variants = [];
        this.variants = new SearchFilter().filterVariants('',this.vehicles);
        break;
      case 4 :
        this.variantSet = false;
        break;
      case 5 : 
        this.mileageSet = false;
        break;
      case 6 :
        this.areaSet = false;
        break;
    }

  }

  setYearFilter(e){
    sessionStorage.setItem('year', e);
    this.temp= [];

    if(this.makeSet == true){

      for(var i = 0 ; i < this.displayArray.length;i++){
        if(this.displayArray[i]['year'] == e){
          this.temp.push(this.displayArray[i]);
        }
  
      }
    }else{
      for(var i = 0 ; i < this.vehicles.length;i++){
        if(this.vehicles[i]['year'] == e){
          this.temp.push(this.vehicles[i]);
        }
  
      }
    }
    if(this.temp.length == 0){
      this.removeItem(2);
      for(var i = 0 ; i < this.vehicles.length;i++){
        if(this.vehicles[i]['year'] == e){
          this.temp.push(this.vehicles[i]);
        }
  
      }


    }else{
      this.displayArray = [];
      this.displayArray = this.temp;
      let searchfilter = new SearchFilter();
      this.makes = [];
      this.makes = searchfilter.filterMakes(e, this.vehicles);
      this.total = this.displayArray.length;
      this.filter = true;
      this.yearSet = true;
    }


  }
  setMakeFilter(e){
    sessionStorage.setItem('make', e);
    this.temp= [];
   
      if(this.yearSet == true){
        for(var i = 0 ; i < this.vehicles.length;i++){
          if(this.vehicles[i]['make'] == e && this.vehicles[i]['year'] == sessionStorage.getItem('year')){
            this.temp.push(this.vehicles[i]);
          }
        }

      }else{
        for(var i = 0 ; i < this.vehicles.length;i++){
          if(this.vehicles[i]['make'] == e){
            this.temp.push(this.vehicles[i]);
          }
         
        }
      }

      if(this.temp.length === 0){
        swal({
          type: 'info',
          title: 'Oops...',
          text: 'There are no  ' + e + "'s in this filter on years = " + sessionStorage.getItem('year') ,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }else{
        this.displayArray = [];
        this.displayArray = this.temp;
        let searchfilter = new SearchFilter();
        this.models = [];
        this.models = searchfilter.filterModels(e, this.vehicles);
        this.total = this.displayArray.length;
        this.filter = true;
        this.makeSet = true;
      }

  }

  setModelFilter(e){
    sessionStorage.setItem('model', e);
    this.temp= [];
    for(var i = 0 ; i < this.displayArray.length;i++){
      if(this.displayArray[i]['model'] == e){
        this.temp.push(this.displayArray[i]);
      }
      
    }
    if(this.temp.length === 0){

    }else{
      this.displayArray = [];
      this.displayArray = this.temp;
      let searchfilter = new SearchFilter();
      this.variants = [];
      this.variants = searchfilter.filterVariants(e, this.vehicles);
      this.total = this.displayArray.length;
      this.filter = true;
      this.modelSet = true;
    }

  }

  setVariantFilter(e){
    sessionStorage.setItem('variant', e);
    this.temp= [];
    for(var i = 0 ; i < this.vehicles.length;i++){
      if(this.vehicles[i]['variant'] == e){
        this.temp.push(this.vehicles[i]);
      }
      this.displayArray = [];
      this.displayArray = this.temp;
      this.total = this.displayArray.length;
      this.filter = true;
      this.variantSet = true;
      
    }
  }

  setMileageFilter(e){
    sessionStorage.setItem('mileage', e);
    this.temp= [];

    this.temp = new SearchFilter().searchMileage(e,this.displayArray);
    this.displayArray = [];
    this.displayArray = this.temp;
    this.total = this.displayArray.length;
    this.filter = true;
    this.mileageSet = true;
  }
  setAreaFilter(e){
    sessionStorage.setItem('area', e);
    this.temp= [];

    this.temp = new SearchFilter().searchArea(e,this.displayArray);
    this.displayArray = [];
    this.displayArray = this.temp;
    this.total = this.displayArray.length;
    this.filter = true;
    this.areaSet = true;
  }


}
