import { Component, OnInit } from '@angular/core';
import { VehiclesServiceService } from '../../services/vehicles-service.service';
import { Vehicle } from '../../models/vehicles';

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.css']
})
export class ListVehiclesComponent implements OnInit {

  vehicles : Array<Vehicle>;
  dealer_id : number;

  constructor(private vehicleServie : VehiclesServiceService) { }

  async ngOnInit() {
    try{
    this.dealer_id = parseInt(sessionStorage.getItem('did'));
    let temp = await this.vehicleServie.getDealerVehicles(this.dealer_id);
    this.vehicles = temp['used'];
    console.log(this.vehicles);
    }
    catch(e){
      console.log(e);
    }
  }

}
