import { Injectable  } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UiStateService {

  
  constructor(private router : Router) { }

  setLoggedIn(){
    sessionStorage.setItem('lgi','true');
  }

  getLoggedIn(){
    let state : Boolean;
    const lgi = sessionStorage.getItem('lgi');
    try{
      if( lgi !== null){
          state = true;
      }else{
        state  = false;
      }

      return state;
    }catch(e){
      console.log(e);
    }
  }

  logout(){
    sessionStorage.removeItem('lgi');
    this.router.navigate(['/']);
  }

}
