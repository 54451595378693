import { Component, OnInit } from '@angular/core';
import { HomeSearchService } from '../../home-search.service';

import { SearchFilter } from '../../search-filter';
import swal from 'sweetalert2';
import { TabDirective } from 'ngx-bootstrap/tabs';
import{ Sorter } from '../../helper-classes/sorter'

@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.css']
})
export class ShowroomComponent implements OnInit {

  vehicles = [];
  criteria : JSON;
  type : string;
  searchedData : string;

  displayArray = [];
  total : number;
  currentPage = 1;
  page : number;

  years = [];
  makes = [];
  models = [];
  variants = [];
  mileages = [];
  areas = [];

  filter : boolean = false;
  yearSet : boolean  = false;
  makeSet : boolean  = false;
  modelSet : boolean  = false;
  variantSet : boolean  = false;
  mileageSet : boolean  = false;
  areaSet : boolean  = false;
  temp  = [];
  criterias = [];


  selectedPriceFilter = 'low_price';
  sorter : Sorter;
  displayLength : number;

  constructor(private homeSearch : HomeSearchService) { }

  ngOnInit() {

    this.vehicles = this.homeSearch.getSearch();
    
    this.criteria = this.homeSearch.getCriteria();
    this.type = this.criteria['type'];

    let make : string;
    let model: string;
    let province : string;

    if(this.criteria['make'] === undefined){
      make = 'Taxis'
    }
    else{
      make = this.criteria['make'];
    }

    if(this.criteria['model'] === undefined){
      model = '';
    }
    else{
      model = this.criteria['model'];
    }

    if(this.criteria['province'] === undefined){
      province ='';
    }
    else{
      province = 'in ' + this.criteria['province'];
    }
    this.searchedData = make +" " + model+ " for Sale " + province + "(" + this.type + ")" ;
    this.initialDisplay();

  }

  initialDisplay(){
    this.displayArray = this.vehicles.slice(0, 10);
    
    this.years = [];

    //Populate side-search bar
    console.log(this.vehicles)
    for(let i = 0 ; i < this.vehicles.length; i++){

        
        if(i == 0){
          this.years.push(this.vehicles[i]['year']);
          this.makes.push(this.vehicles[i]['make']);
          this.models.push(this.vehicles[i]['model']);
          this.variants.push(this.vehicles[i]['variant']);
          this.mileages.push(this.vehicles[i]['mileage']);
          this.areas.push(this.vehicles[i]['province']);
        }
        if(i != 0){
          if(this.years.includes(this.vehicles[i]['year']) === false){
            this.years.push(this.vehicles[i]['year']);
          }
          else if(this.makes.includes(this.vehicles[i]['make']) === false){
            this.makes.push(this.vehicles[i]['make']);
          }
          else if(this.models.includes(this.vehicles[i]['model']) === false){
            this.models.push(this.vehicles[i]['model']);
          }
          else if(this.variants.includes(this.vehicles[i]['variant']) === false){
            this.variants.push(this.vehicles[i]['variant']);
          }
          else if(this.mileages.includes(this.vehicles[i]['mileage']) === false){
            this.mileages.push(this.vehicles[i]['mileage']);
          }
          else if(this.areas.includes(this.vehicles[i]['province']) === false){
            this.areas.push(this.vehicles[i]['province']);
          }
      }
    }
    this.years = this.years.sort(function(a, b){return b-a});
    this.makes = this.makes.sort();
    this.models = this.models.sort();
    this.variants = this.variants.sort();
    this.mileages = this.mileages.sort();
    this.areas = this.areas.sort();
   
    this.filter = false;
    this.yearSet = false;
    this.makeSet = false;
    this.modelSet = false;
    this.variantSet = false;
    this.mileageSet = false;
    this.areaSet = false;
  }

  sortPrice(criteria){
    console.log(criteria);
    
    if(this.filter){
      this.sorter = new Sorter(this.temp);
    }else{
      this.sorter = new Sorter(this.vehicles);
    }

    switch(criteria){
      case "low_price" : 
        if(this.filter === false){
          this.vehicles =  this.sorter.priceLowToHigh();
          this.displayArray = [];
          this.displayArray = this.vehicles.slice(0, 10);
          
          this.displayLength = this.displayArray.length;
          this.selectedPriceFilter = criteria;
        }else{
          this.temp =  this.sorter.priceLowToHigh();
          this.displayArray = [];
          this.displayArray = this.temp.slice(0, 10);
          this.displayLength = this.displayArray.length;
          this.selectedPriceFilter = criteria;
        }

         break;
      case "high_price" :
        if(this.filter === false){
          this.selectedPriceFilter = criteria
          this.vehicles = this.sorter.priceHighToLow();
          this.displayArray = [];
          this.displayArray = this.vehicles.slice(0, 10);
          this.displayLength = this.displayArray.length;
        }else{
          this.selectedPriceFilter = criteria
          this.temp = this.sorter.priceHighToLow();
          this.displayArray = [];
          this.displayArray = this.temp.slice(0, 10);
          this.displayLength = this.displayArray.length;
        }

        break;
    }
  }

  pageChanged(event: any): void {
    this.page = event.page;
    
    if(this.filter === false){
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.displayArray = this.vehicles.slice(startItem, endItem);
    }
    else if(this.filter === true){
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.displayArray = this.temp.slice(startItem, endItem);
    }

    
  }

  removeItem(e){
    
    switch(e){
      case 1:
        this.yearSet = false;
        this.criterias = [this.yearSet, this.makeSet, this.modelSet , this.variantSet];
        this.displayArray = new SearchFilter().adjustContent(this.criterias, this.vehicles);
        
        this.makes = [];
        this.makes = new SearchFilter().filterMakes('',this.vehicles);
        break;
      case 2 : 
        this.makeSet = false;
        this.criterias = [this.yearSet, this.makeSet, this.modelSet , this.variantSet];
        this.displayArray = new SearchFilter().adjustContent(this.criterias, this.vehicles);
        this.models = [];
        this.models = new SearchFilter().filterModels('',this.vehicles);
        break;
      case 3 : 
        this.modelSet = false;
        this.variants = [];
        this.variants = new SearchFilter().filterVariants('',this.vehicles);
        break;
      case 4 :
        this.variantSet = false;
        break;
      case 5 : 
        this.mileageSet = false;
        break;
      case 6 :
        this.areaSet = false;
        break;
    }

  }

  setYearFilter(e){
    sessionStorage.setItem('year', e);
    this.temp= [];

    if(this.makeSet == true){

      for(var i = 0 ; i < this.displayArray.length;i++){
        if(this.displayArray[i]['year'] == e){
          this.temp.push(this.displayArray[i]);
        }
  
      }
    }else{
      for(var i = 0 ; i < this.vehicles.length;i++){
        if(this.vehicles[i]['year'] == e){
          this.temp.push(this.vehicles[i]);
        }
  
      }
    }
    if(this.temp.length == 0){
      this.removeItem(2);
      for(var i = 0 ; i < this.vehicles.length;i++){
        if(this.vehicles[i]['year'] == e){
          this.temp.push(this.vehicles[i]);
        }
  
      }


    }else{
      this.displayArray = [];
      this.displayArray = this.temp;
      let searchfilter = new SearchFilter();
      this.makes = [];
      this.makes = searchfilter.filterMakes(e, this.vehicles);
      this.total = this.displayArray.length;
      this.filter = true;
      this.yearSet = true;
    }


  }
  setMakeFilter(e){
    sessionStorage.setItem('make', e);
    this.temp= [];
   
      if(this.yearSet == true){
        for(var i = 0 ; i < this.vehicles.length;i++){
          if(this.vehicles[i]['make'] == e && this.vehicles[i]['year'] == sessionStorage.getItem('year')){
            this.temp.push(this.vehicles[i]);
          }
        }

      }else{
        for(var i = 0 ; i < this.vehicles.length;i++){
          if(this.vehicles[i]['make'] == e){
            this.temp.push(this.vehicles[i]);
          }
         
        }
      }

      if(this.temp.length === 0){
        swal({
          type: 'info',
          title: 'Oops...',
          text: 'There are no  ' + e + "'s in this filter on years = " + sessionStorage.getItem('year') ,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }else{
        this.displayArray = [];
        this.displayArray = this.temp;
        let searchfilter = new SearchFilter();
        this.models = [];
        this.models = searchfilter.filterModels(e, this.vehicles);
        this.total = this.displayArray.length;
        this.filter = true;
        this.makeSet = true;
      }

  }

  setModelFilter(e){
    sessionStorage.setItem('model', e);
    this.temp= [];
    for(var i = 0 ; i < this.displayArray.length;i++){
      if(this.displayArray[i]['model'] == e){
        this.temp.push(this.displayArray[i]);
      }
      
    }
    if(this.temp.length === 0){

    }else{
      this.displayArray = [];
      this.displayArray = this.temp;
      let searchfilter = new SearchFilter();
      this.variants = [];
      this.variants = searchfilter.filterVariants(e, this.vehicles);
      this.total = this.displayArray.length;
      this.filter = true;
      this.modelSet = true;
    }

  }

  setVariantFilter(e){
    sessionStorage.setItem('variant', e);
    this.temp= [];
    for(var i = 0 ; i < this.vehicles.length;i++){
      if(this.vehicles[i]['variant'] == e){
        this.temp.push(this.vehicles[i]);
      }
      this.displayArray = [];
      this.displayArray = this.temp;
      this.total = this.displayArray.length;
      this.filter = true;
      this.variantSet = true;
      
    }
  }

  setMileageFilter(e){
    sessionStorage.setItem('mileage', e);
    this.temp= [];

    this.temp = new SearchFilter().searchMileage(e,this.displayArray);
    this.displayArray = [];
    this.displayArray = this.temp;
    this.total = this.displayArray.length;
    this.filter = true;
    this.mileageSet = true;
  }
  setAreaFilter(e){
    sessionStorage.setItem('area', e);
    this.temp= [];

    this.temp = new SearchFilter().searchArea(e,this.displayArray);
    this.displayArray = [];
    this.displayArray = this.temp;
    this.total = this.displayArray.length;
    this.filter = true;
    this.areaSet = true;
  }


}
