import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { UiStateService } from '../app/services/ui-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'taxis4sa';
  _appLoggedIn : Boolean;

  constructor(private router : Router, private uiState : UiStateService){

    router.events.subscribe((val) => {
     this._appLoggedIn =  uiState.getLoggedIn();
    });

  }

}
