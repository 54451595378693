export class Sorter{

    dataSet : Array<any>;
    

    public constructor(vehicle : Array<any> ){
        this.dataSet = vehicle;
        console.log(this.dataSet);
        this.bubbleSort();
    }

    bubbleSort(){
        let swapped;
       
        do{
            swapped = false;
            for(let i = 0; i < this.dataSet.length; i++){
                try{
                    if(this.dataSet[i]['price'] > this.dataSet[i+1]['price']){
                        let temp  = this.dataSet[i];
                        this.dataSet[i] = this.dataSet[i+1];
                        this.dataSet[i+1] = temp;
                        swapped = true;
    
                    }
                }
                catch(exception){
                    console.log(exception);
                }


            }
        } while (swapped);

    }

    priceLowToHigh(): Array<any>{
        return this.dataSet;
    }

    priceHighToLow() : Array<any>{
         return this.dataSet.reverse();
    }
}
