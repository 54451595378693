import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../models/vehicles'

@Injectable({
  providedIn: 'root'
})
export class VehiclesServiceService {

  url = 'http://www.taxis4sa.co.za/taxis4sabeta/api.php/';

  constructor(private http :HttpClient) { }

  async getDealerVehicles(id){
    const vehicles = await this.http.get<Array<Vehicle>>(this.url + 'used?transform=1&filter=dealer_code,eq,'+id)
    .toPromise();
    return vehicles;
  }

  async getVehicle(id){
    const vehicles = await this.http.get<Array<Vehicle>>(this.url + 'used?transform=1&filter=id,eq,'+id)
    .toPromise();
    
    return vehicles;
  }

  async editVehicle(vehicle : Vehicle){
   let message : string = "An error occured whilst making changes, please retry.";
   const result =  await this.http.put(this.url+ 'used/' + vehicle.id, vehicle)
    .toPromise();
    if(result === 1){
      message = "Success!";
    }
    return message;
  }

}
