import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CustomerContactComponent } from '../../dialogs/customer-contact/customer-contact.component';
import { VehiclesServiceService } from '../../services/vehicles-service.service'
import { Vehicle } from 'src/app/models/vehicles';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

   vehicles  = [];
   messages = [];
   hasMessages : Boolean = false;
    dealerId : number;
  constructor(public dialog: MatDialog, private vehicleService : VehiclesServiceService, private userService : UserService) { }

  ngOnInit() {
    this.getVehicles();
    
  }

  async getVehicles(){
    try{
      this.dealerId = parseInt(sessionStorage.getItem('did'));
     const res = await this.vehicleService.getDealerVehicles(this.dealerId);
     const contact = await this.userService.getDealerMessages(this.dealerId);
      this.vehicles = res['used'];
      this.messages = contact['dealerContact'];
      console.log(this.messages);
      if(this.messages.length !== 0){
        this.hasMessages = true;
      }
    }
    catch(e){
      console.log(e);
    }
  }



  openDialog(id): void {
    const dialogRef = this.dialog.open(CustomerContactComponent, {
      width: '500px',
      data: this.messages[id]
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.name = result;
    // });
  }



}
