import { Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DealerContact } from '../../models/dealerContact';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.css']
})
export class CustomerContactComponent {

  constructor(public dialogRef: MatDialogRef<CustomerContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DealerContact) {
      console.log(data);
     }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
