import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  news  = [];
  constructor(private http : HttpClient) { }

  ngOnInit() {
    this.getNews();
  }

  getNews(){

    this.http.get('http://www.taxis4sa.co.za/taxis4sabeta/api.php/Taxis4saNews?transform=1&order=id,desc&page=1,3')
    .subscribe((res : JSON) =>{
        let news = res['Taxis4saNews'];
        this.news = news;


    });

  }

}
